<template>
	<div class="modal fade" id="changeStageModal" ref="changeStageModal" tabindex="-1" aria-labelledby="Смена этапа воронки" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-3">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-1">
					<span v-if="!this.isEmpty(this.stage_name)" class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">Закрытие этапа «{{ this.stage_name }}»</span>
					<span v-else class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">Закрытие этапа</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>

				<div class="modal-body d-flex flex-column p-1">
					<ul v-show="!this.isEmpty(this.all_errors)" class="alert rounded-3 alert-danger mb-3">
						<li v-for="(error, index) in this.all_errors" :key="index" v-text="error" />
					</ul>

					<div v-if="!this.isEmpty(this.stage)" class="d-flex flex-column">

						<!-- Следующий этап -->
						<!-- div v-if="!this.isEmpty(this.stage.next_steps_list)">
							<SelectBox inputId="next_step"
									   inputName="next_step"
									   ref="next_step"
									   inputLabel="Следующий этап:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputValue="this.stage.next_step"
									   :inputOptions="this.stage.next_steps_list"
									   inputRequired="true"
									   @setValue="(value) => this.setAndValidate(field.type, 'next_step', value)" />
						</div -->
						<!-- /Следующий этап -->

						<!-- Файлы для скачивания -->
						<div v-if="!this.isEmpty(this.stage.files_list)">
							<label class="mb-3 text-dark-gray">Файлы для скачивания:</label>
							<ul class="list-unstyled d-flex flex-column">
								<li v-for="(file, index) of this.stage.files_list"
									:key="index"
									class="list-item">
									<a :href="file.path"
									   class="d-inline-flex align-items-center mb-2 link border-bottom-0 text-decoration-none mw-90"
									   :download="file.file_name + file.file_type">
										<img v-if="this.inArray(file.file_type, ['doc', 'docx'])" :src="require('@/assets/icons/file-word.svg')" alt="Документ Word" width="18" height="18" class="me-2">
										<img v-else-if="this.inArray(file.file_type, ['xls', 'xlsx'])" :src="require('@/assets/icons/file-excel.svg')" alt="Документ Excel" width="18" height="18" class="me-2">
										<img v-else-if="this.inArray(file.file_type, ['ppt', 'pptx'])" :src="require('@/assets/icons/file-powerpoint.svg')" alt="Документ PowerPoint" width="18" height="18" class="me-2">
										<img v-else-if="this.inArray(file.file_type, ['pdf'])" :src="require('@/assets/icons/file-pdf.svg')" alt="PDF-документ" width="18" height="18" class="me-2">
										<img v-else-if="this.inArray(file.file_type, ['jpg','jpeg','png','bmp','tiff','gif'])" :src="require('@/assets/icons/file-image.svg')" alt="Изображение" width="18" height="18" class="me-2">
										<img v-else :src="require('@/assets/icons/blank-icon.svg')" alt="Файл" width="18" height="18" class="me-2">
										<span class="me-10 text-nowrap-mask pb-1 pe-4">
											<span class="fs-4 link link-dashed">{{ file.file_name }}</span>
										</span>
									</a>
								</li>
							</ul>
						</div>
						<!-- /Файлы для скачивания -->

						<!-- Список полей для заполнения при завершении этапа -->
						<div v-if="!this.isEmpty(this.stage.fields_list)">
							<div class="needs-validation">
								<div v-for="(field, index) of this.stage.fields_list" :key="index">
									<div v-if="field.type == 'text'" class="col mb-3">
										<InputText :inputId="field.name"
												   :inputName="field.name"
												   :ref="field.name"
												   :inputLabel="field.label + ':'"
												   :inputValue="field.value"
												   :inputRequired="field.is_required"
												   inputLabelClass="text-font-secondary mb-3"
												   inputClass="rounded-3 p-2"
												   inputWrapClass="mb-20"
												   @setValue="(value) => this.setAndValidate(field.type, field.name, value)" />
									</div>
									<div v-if="field.type == 'textarea'" class="col mb-3">
										<TextArea :inputId="field.name"
												  :inputName="field.name"
												  :ref="field.name"
												  :inputLabel="field.label + ':'"
												  :inputValue="field.value"
												  :inputRequired="field.is_required"
												  inputLabelClass="text-font-secondary mb-3"
												  inputClass="rounded-3 p-2"
												  inputWrapClass="mb-20"
												  @setValue="(value) => this.setAndValidate(field.type, field.name, value)" />
									</div>
									<div v-if="field.type == 'datetime'" class="col mb-3">
										<DateTimePicker :inputId="field.name"
														:inputName="field.name"
														:ref="field.name"
														inputLabelClass="text-font-secondary mb-3"
														inputClass="rounded-3 p-2"
														inputWrapClass="mb-20"
														:inputLabel="field.label + ':'"
														:inputValue="(field.value) ? field.value : false"
														:inputRequired="field.is_required"
														displayFormat="DD.MM.YYYY"
														@setValue="(value) => this.setAndValidate(field.type, field.name, value)" />
									</div>
									<div v-if="field.type == 'file'" class="col mb-3">
										<InputFile :inputId="field.name"
												   :inputName="field.name"
												   :ref="field.name"
												   :inputLabel="field.label + ':'"
												   :inputValue="field.value"
												   :inputRequired="field.is_required"
												   inputLabelClass="text-font-secondary mb-3"
												   inputClass="rounded-3 p-2"
												   inputWrapClass="mb-20"
												   inputRemove="true"
												   inputMultiple="true"
												   @removeValue="(value) => this.removeFile(field.name, value)"
												   @setValue="(value) => this.uploadFile(field.name, value)" />
									</div>
									<div v-if="field.type == 'select'" class="col mb-3">
										<InputSelect :inputId="field.name"
													 :inputName="field.name"
													 :ref="field.name"
													 :inputLabel="field.label + ':'"
													 :inputValue="field.value"
													 :inputOptions="field.select"
													 :inputRequired="field.is_required"
													 inputLabelClass="text-font-secondary mb-3"
													 inputClass="rounded-3 p-2"
													 inputUsePlaceholder="true"
													 inputWrapClass="mb-20"
													 @setValue="(value) => this.setAndValidate(field.type, field.name, value)" />
									</div>
									<div v-if="field.type == 'checkbox'" class="col mb-3">
										<span class="d-block mb-3 text-secondary" style="max-width: 350px;">
											{{ field.label }}:
											<sup v-if="field.is_required" class="text-danger">*</sup>
										</span>
										<ul class="d-flex flex-wrap m-0 p-0 mb-10 list-unstyled" >
											<li class="mb-1 me-auto" style="width: 50%;">
												<button class="p-10 d-flex align-items-center btn btn-outline dropdown-item rounded-3"
														@click="this.newTaskEvent(field.name, 'call')">
													<div class="d-flex align-items-center justify-content-center bg-primary rounded-circle me-10" style="width:34px; height: 34px;">
														<img :src="require('@/assets/icons/phone-icon.svg')" alt="Звонок" width="16" height="16" class="d-block">
													</div>
													<span class="font-semi fw-semi">Звонок</span>
												</button>
											</li>
											<li class="mb-1 me-auto" style="width: 50%;">
												<button class="p-10 d-flex align-items-center btn btn-outline dropdown-item rounded-3"
														@click="this.newTaskEvent(field.name, 'meet')">
													<div class="d-flex align-items-center justify-content-center bg-pink rounded-circle me-10" style="width:34px; height: 34px;">
														<img :src="require('@/assets/icons/bag-icon.svg')" alt="Встреча" width="16" height="16" class="d-block">
													</div>
													<span class="font-semi fw-semi">Встреча</span>
												</button>
											</li>
											<li class="mb-1 me-auto" style="width: 50%;">
												<button class="p-10 d-flex align-items-center btn btn-outline dropdown-item rounded-3"
														@click="this.newTaskEvent(field.name, 'show')">
													<div class="d-flex align-items-center justify-content-center bg-warning rounded-circle me-10" style="width:34px; height: 34px;">
														<img :src="require('@/assets/icons/eye-icon.svg')" alt="Показ" width="16" height="16" class="d-block">
													</div>
													<span class="font-semi fw-semi">Показ</span>
												</button>
											</li>
											<li class="mb-1 me-auto" style="width: 50%;">
												<button class="p-10 d-flex align-items-center btn btn-outline dropdown-item rounded-3"
														@click="this.newTaskEvent(field.name, 'deal')">
													<div class="d-flex align-items-center justify-content-center bg-raspberry rounded-circle me-10" style="width:34px; height: 34px;">
														<img :src="require('@/assets/icons/deal-icon.svg')" alt="Сделка" width="16" height="16" class="d-block">
													</div>
													<span class="font-semi fw-semi">Сделка</span>
												</button>
											</li>
										</ul>
										<div v-if="!this.isEmpty(field.value)" class="col col-12">
											<ul class="list-unstyled list-group mb-3">
												<li v-for="(task, index) in field.value" :key="index" class="list-item mb-1 fs-4">
													<span v-if="task.type == 'call'" class="fw-bold text-success">{{ task.name }} {{ task.date }}</span>
													<span v-else-if="task.type == 'show' || task.type == 'showing'" class="fw-bold text-warning">{{ task.name }} {{ task.date }}</span>
													<span v-else-if="task.type == 'meet' || task.type == 'meeting'" class="fw-bold text-pink">{{ task.name }} {{ task.date }}</span>
													<span v-else-if="task.type == 'deal'" class="fw-bold text-danger">{{ task.name }} {{ task.date }}</span>
													<span v-else class="fw-bold text-warning">{{ task.name }} {{ task.date }}</span>
												</li>
											</ul>
										</div>
									</div>
									<div v-if="field.type == 'number'" class="col mb-3">
										<InputText :inputId="field.name"
													 :inputName="field.name"
													 :ref="field.name"
													 :inputLabel="field.label + ':'"
													 :inputValue="field.value"
													 :inputRequired="field.is_required"
													 inputLabelClass="text-font-secondary mb-3"
													 inputClass="rounded-3 p-2"
													 inputWrapClass="mb-20"
													 @setValue="(value) => this.setAndValidate(field.type, field.name, value)" />
									</div>
								</div>
							</div>
						</div>
						<!-- /Список полей для заполнения при завершении этапа -->

						<div v-if="!this.isEmpty(this.stage.linking_steps)">
							<div class="needs-validation">
								<div v-for="(field, key) in this.stage.linking_steps" :key="field.id">
									<InputText v-if="field.type == 0"
									   :inputId="'fields_' + key"
									   :inputName="'fields_' + key"
									   :ref="'fields.' + key"
									   :input-label="field.label + ':'"
									   :input-value="field.value"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :input-required="field.is_required"
									   @setValue="(value) => { this.stage.linking_steps[key].value = value }" />

							<InputNumber v-else-if="field.type == 1"
										 :inputId="'fields_' + key"
										 :inputName="'fields_' + key"
										 :ref="'fields.' + key"
										 :input-label="field.label + ':'"
										 :input-value="field.value"
										 inputLabelClass="text-font-secondary mb-3"
										 inputClass="rounded-3 p-2"
										 inputWrapClass="mb-20"
										 :input-required="field.is_required"
										 @setValue="(value) => { this.stage.linking_steps[key].value =  parseInt(value)}" />

							<SelectBox v-else-if="field.type == 2"
									   :inputId="'fields_' + key"
									   :inputName="'fields_' + key"
									   :ref="'fields.' + key"
									   :input-label="field.label + ':'"
									   :input-value="field.value"
									   :input-options="(!this.isEmpty(field.params.items)) ? field.params.items : null"
									   input-multiple="false"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :input-required="field.is_required"
									   @setValue="(value) => { this.stage.linking_steps[key].value = value }" />

							<SelectBox v-else-if="field.type == 3"
									   :inputId="'fields_' + key"
									   :inputName="'fields_' + key"
									   :ref="'fields.' + key"
									   :input-label="field.label + ':'"
									   :input-value="field.value"
									   :input-options="(!this.isEmpty(field.params.items)) ? field.params.items : null"
									   input-multiple="true"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :input-required="field.is_required"
									   @setValue="(value) => { this.stage.linking_steps[key].value = value }" />

							<Switcher v-else-if="field.type == 4"
									  :inputId="'fields_' + key"
									  :inputName="'fields_' + key"
									  :ref="'fields.' + key"
									  :input-label="field.label"
									  :input-value="(!this.isEmpty(field.value)) ? field.value : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  :input-required="field.is_required"
									  @setValue="(value) => { this.stage.linking_steps[key].value = parseInt(value) }" />

							<TextArea v-else-if="field.type == 5"
									  :inputId="'fields_' + key"
									  :inputName="'fields_' + key"
									  :ref="'fields.' + key"
									  :input-label="field.label + ':'"
									  :input-value="field.value"
									  inputLabelClass="text-font-secondary mb-3"
									  inputClass="rounded-3 p-2"
									  inputWrapClass="mb-20"
									  :input-required="field.is_required"
									  @setValue="(value) => this.setAndValidate(field.type, 'fields.' + key, value)" />
								</div>
							</div>
						</div>

						<!-- Ответственные -->
						<div v-if="!this.isEmpty(this.doers_list)">
							<SelectBox inputId="employees"
									   inputName="employees"
									   inputLabelClass="text-font-secondary mb-3"
									   inputLabel="Ответственный"
									   :inputOptions="this.doers_list"
									   :inputValue="this.stage.doers"
									   inputClass="rounded-1 p-2"
									   inputMultiple="true"
									   inputSearch="true"
									   inputUsePlaceholder="true"
									   inputWrapClass="px-20"
									   @setValue="(value) => this.setupFilter('doers', value)" />
						</div>
						<!-- /Ответственные -->

						<div v-if="this.stage.is_close_after === true && !this.isEmpty(this.client_id)">
							<div class="form-text d-flex fs-4 mb-3">
								<img :src="require('@/assets/icons/warning.svg')" alt="Warning" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: 1px;">
								Для закрытия данного этапа необходимо закрытие Клиента.
							</div>

							<h3 class="modal-title fs-3 mb-3 fw-semi font-semi lh-1">Укажите причину закрытия</h3>
							<InputSelect v-show="!this.client.is_deal_done"
										 inputId="client_denial_work"
										 inputLabel="Выберите причину:"
										 inputName="client_denial_work"
										 ref="client_denial_work"
										 :inputOptions="this.denial_work_list"
										 :inputValue="this.client.denial_work"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 :inputRequired="true"
										 @setValue="(value) => { this.client.denial_work = value }" />

							<TextArea inputId="client_comment"
									  inputName="client_comment"
									  ref="client_comment"
									  inputLabel="Комментарий:"
									  :inputValue="this.client.comment"
									  inputLabelClass="text-font-secondary mb-3"
									  inputClass="rounded-3 p-2"
									  inputWrapClass="mb-20"
									  inputRequired="true"
									  @setValue="(value) => { this.client.comment = value }" />

							<Switcher inputId="client_is_deal_done"
									  inputName="client_is_deal_done"
									  ref="client_is_deal_done"
									  inputLabel="По клиенту прошла сделка"
									  :inputValue="this.client.is_deal_done"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => { this.client.is_deal_done = value }" />

							<InputText v-show="this.client.is_deal_done"
									   inputId="client_commission"
									   inputName="client_commission"
									   ref="client_commission"
									   :inputValue="this.client.commission"
									   inputLabel="Комиссия:"
									   inputPlaceholder="Размер комиссии в руб."
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   @setValue="(value) => { this.client.commission = value }" />
						</div>
						<div v-else-if="this.stage.is_close_after === true && !this.isEmpty(this.requisition_id)">
							<div class="form-text d-flex fs-4 mb-3">
								<img :src="require('@/assets/icons/warning.svg')" alt="Warning" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: 1px;">
								Для закрытия данного этапа необходимо закрытие Заявки.
							</div>

						</div>

<!--							<div v-if="!this.isEmpty(this.stage.prev_step.name) && ((this.funnel_id == 0 && !this.isEmpty(this.stage.prev_step)) || this.isEmpty(this.stage.fields_list))" class="form-text d-flex fs-4 mb-3">
							<img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: 1px;">
							Вы можете закрыть текущий этап как успешный или вернуться на этап «{{ this.stage.prev_step.name }}».
						</div>
						<div v-else-if="this.isEmpty(this.stage.prev_step.name) && ((this.funnel_id == 0 && !this.isEmpty(this.stage.prev_step)) || this.isEmpty(this.stage.fields_list))" class="form-text d-flex fs-4 mb-3">
							<img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: 1px;">
							Вы можете закрыть текущий этап как успешный или вернуться на один из этапов.
						</div>
						<div v-else-if="this.funnel_id == 0 || this.isEmpty(this.stage.fields_list)" class="form-text d-flex fs-4 mb-3">
							<img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: 1px;">
							Вы можете закрыть текущий этап как успешный.
						</div>-->

					</div>
					<div v-else-if="!this.is_last_stage" class="d-flex flex-column">
						<Loader />
					</div>

					<div v-else class="d-flex flex-column">
						<div class="form-text d-flex fs-4 mb-3">
							<img :src="require('@/assets/icons/warning.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: 1px;">
							Похоже уже достигнут последний этап воронки.
						</div>
					</div>

					<div v-if="!this.isEmpty(this.stage.after_close)" class="d-flex flex-column">
						<h3>Дополнительные действия при закрытии этапа</h3>
						<div v-if="!this.isEmpty(this.stage.after_close.use_in_advert_turn_off) && !this.isEmpty(this.item.object)" class="row align-items-center flex-row mb-2">
							<div class="row-fluid mb-2">
								<div class="col col-12">
									<label for="object_advert" class="form-label col text-dark-gray">
										Реклама объекта ID: {{ this.item.object.id }}
									</label>
								</div>
								<div id="object_advert" class="col col-12">
									<div>
										<Switcher inputLabel="В рекламу"
												  inputClass="ps-0"
												  :inputValue="this.item.object.use_in_advert"
												  @setValue="(value) => this.sendObjectToAdverts(this.item.object.id, value, $event)" />
									</div>
									<div class="row-fluid px-2" :class="{'d-none' : !this.item.object.use_in_advert}">
										<div class="col-12 mt-3">
											<div v-if="!this.isEmpty(this.moderation_errors)" class="mt-3">
												<div v-for="(error, index) of this.moderation_errors"
													 :key="index" class="alert alert-danger rounded-3 mb-3">
													{{ error }}
												</div>
											</div>
											<div class="row row-cols-2 pe-3">
												<div v-if="this.user_can.advertise.zipal" class="d-flex col pb-2">
													<a v-if="(this.item.object.services.advertised.zipal)" href="javascript:{}"
													   :class="['btn btn-ads', {
													   'active': (this.item.object.services.advertised.zipal && !this.item.object.services.errors.zipal),
													   'border-danger': (this.item.object.services.errors.zipal),
												   }]"
													   @click="addRemoveObjectAdverts(this.item.object.id, 'zipal', $event)"
													   title="С отчётами">
														С отчётами
													</a>
													<a v-else href="javascript:{}"
													   :class="['btn btn-ads', {
													   'active': (this.item.object.services.advertised.zipal && !this.zipal_error),
													   'border-danger': this.zipal_error,
												   }]"
													   data-bs-toggle="modal"
													   data-bs-target="#advertDaysModal"
													   data-bs-service="zipal"
													   :data-bs-object-id="this.item.object.id"
													   title="С отчётами"
													   @click="this.add_advert_is_open = true">
														С отчётами
													</a>
													<button v-show="this.zipal_error" class="btn btn-help px-1"
															data-bs-toggle="popover"
															data-bs-placement="top"
															:data-bs-target="'#advertsError' + this.item.object.id"
															data-bs-container="body"
															data-bs-class="popover-info start-0 end-0 border-danger text-danger shadow">
														<img :src="require('@/assets/icons/attention.svg')" alt="Ошибка" height="26" />
													</button>
												</div>
												<div v-if="this.user_can.advertise.free" class="d-flex col pb-2">
													<a v-if="(this.item.object.services.advertised.free)" href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.free) ? 'active' : '']"
													   @click="addRemoveObjectAdverts(this.item.object.id, 'free', $event)"
													   title="Бесплатные площадки">
														Бесплатные
													</a>
													<a v-else href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.free) ? 'active' : '']"
													   data-bs-toggle="modal"
													   data-bs-target="#advertDaysModal"
													   data-bs-service="free"
													   :data-bs-object-id="this.item.object.id"
													   title="Бесплатные площадки"
													   @click="this.add_advert_is_open = true">
														Бесплатные
													</a>
												</div>
												<div v-if="this.user_can.advertise.avito" class="d-flex col pb-2">
													<a v-if="(this.item.object.services.advertised.avito)" href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.avito) ? 'active' : '']"
													   @click="addRemoveObjectAdverts(this.item.object.id, 'avito', $event)"
													   title="Размещение на Avito">
														<img :src="require('@/assets/svg/avito-logo.svg')" alt="Avito" height="36" />
													</a>
													<a v-else href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.avito) ? 'active' : '']"
													   data-bs-toggle="modal"
													   data-bs-target="#advertDaysModal"
													   data-bs-service="avito"
													   :data-bs-object-id="this.item.object.id"
													   title="Размещение на Avito"
													   @click="this.add_advert_is_open = true">
														<img :src="require('@/assets/svg/avito-logo.svg')" alt="Avito" height="36" />
													</a>
												</div>
												<div v-if="this.user_can.advertise.cian" class="d-flex col pb-2">
													<a v-if="(this.item.object.services.advertised.cian)" href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.cian) ? 'active' : '']"
													   @click="addRemoveObjectAdverts(this.item.object.id, 'cian', $event)"
													   title="Размещение на ЦИАН">
														<img :src="require('@/assets/svg/cian-logo.svg')" alt="ЦИАН" height="36" />
													</a>
													<a v-else href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.cian) ? 'active' : '']"
													   data-bs-toggle="modal"
													   data-bs-target="#advertDaysModal"
													   data-bs-service="cian"
													   :data-bs-object-id="this.item.object.id"
													   title="Размещение на ЦИАН"
													   @click="this.add_advert_is_open = true">
														<img :src="require('@/assets/svg/cian-logo.svg')" alt="ЦИАН" height="36" />
													</a>
												</div>
												<div v-if="this.user_can.advertise.zipal" class="d-flex col pb-2">
													<a href="javascript:{}"
													   :class="['btn btn-ads', {
														   'active': (this.item.object.services.advertised.zipal && !this.packages_error),
														   'border-danger': this.packages_error,
													   }]"
													   data-bs-toggle="modal"
													   data-bs-target="#advertDaysModal"
													   data-bs-service="packages"
													   :data-bs-object-id="this.item.object.id"
													   title="Пакетное размещение"
													   @click="this.add_advert_is_open = true">
														Пакеты
													</a>
													<button v-show="this.packages_error" class="btn btn-help px-1"
															data-bs-toggle="popover"
															data-bs-placement="top"
															:data-bs-target="'#advertsError' + this.item.object.id"
															data-bs-container="body"
															data-bs-class="popover-info start-0 end-0 border-danger text-danger shadow">
														<img :src="require('@/assets/icons/attention.svg')" alt="Ошибка" height="26" />
													</button>
												</div>
												<div v-if="this.user_can.advertise.domclick" class="d-flex col pb-2">
													<a v-if="(this.item.object.services.advertised.domclick)" href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.domclick) ? 'active' : '']"
													   @click="addRemoveObjectAdverts(this.item.object.id, 'domclick', $event)"
													   title="Размещение на ДомКлик">
														<img :src="require('@/assets/svg/domclick-logo.svg')" alt="ДомКлик" height="36" />
													</a>
													<a v-else href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.domclick) ? 'active' : '']"
													   data-bs-toggle="modal"
													   data-bs-target="#advertDaysModal"
													   data-bs-service="domclick"
													   :data-bs-object-id="this.item.object.id"
													   title="Размещение на ДомКлик"
													   @click="this.add_advert_is_open = true">
														<img :src="require('@/assets/svg/domclick-logo.svg')" alt="ДомКлик" height="36" />
													</a>
												</div>
												<!-- div v-if="this.user_can.advertise.emls" class="d-flex col">
													<a href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.emls) ? 'active' : '']"
													   @click="addRemoveObjectAdverts(this.item.object.id, 'emls', $event)"
													   title="Размещение на EMLS">
														<img :src="require('@/assets/svg/emls-logo.svg')" alt="EMLS" height="36" />
													</a>
												</div -->
												<div v-if="this.user_can.advertise.jcat" class="d-flex col pb-2">
													<a v-if="(this.item.object.services.advertised.jcat)" href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.jcat) ? 'active' : '']"
													   @click="addRemoveObjectAdverts(this.item.object.id, 'jcat', $event)"
													   title="Размещение на JCat">
														<img :src="require('@/assets/svg/jcat-logo.svg')" alt="JCat" height="36" />
													</a>
													<a v-else href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.jcat) ? 'active' : '']"
													   data-bs-toggle="modal"
													   data-bs-target="#advertDaysModal"
													   data-bs-service="jcat"
													   :data-bs-object-id="this.item.object.id"
													   title="Размещение на JCat"
													   @click="this.add_advert_is_open = true">
														<img :src="require('@/assets/svg/jcat-logo.svg')" alt="JCat" height="36" />
													</a>
												</div>
												<div v-if="this.user_can.advertise.yandex" class="d-flex col pb-2">
													<a v-if="(this.item.object.services.advertised.yandex)" href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.yandex) ? 'active' : '']"
													   @click="addRemoveObjectAdverts(this.item.object.id, 'yandex', $event)"
													   title="Размещение на Яндекс.Недвижимость">
														<img :src="require('@/assets/svg/yandex-logo.svg')" alt="Яндекс.Недвижимость" height="36" />
													</a>
													<a v-else href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.advertised.yandex) ? 'active' : '']"
													   data-bs-toggle="modal"
													   data-bs-target="#advertDaysModal"
													   data-bs-service="yandex"
													   :data-bs-object-id="this.item.object.id"
													   title="Размещение на Яндекс.Недвижимость"
													   @click="this.add_advert_is_open = true">
														<img :src="require('@/assets/svg/yandex-logo.svg')" alt="Яндекс.Недвижимость" height="36" />
													</a>
												</div>
												<div v-if="this.user_can.advertise.promotion && this.$props.layout == 'adverts'" class="d-flex col pb-2">
													<a href="javascript:{}"
													   :class="['btn btn-ads', (this.item.object.services.is_promotion) ? 'active' : '']"
													   data-bs-toggle="modal"
													   data-bs-target="#advertPromotionModal"
													   :data-bs-object-id="this.item.object.id"
													   title="Продвижение"
													   @click="this.add_advert_is_open = true">
														Продвижение
													</a>
												</div>
											</div>
											<div v-if="this.user_can.moderate_adverts && !this.isEmpty(this.item.moderation)" class="d-flex pt-2 col-12">
												<div v-if="!this.item.object.services.is_moderated" class="d-flex col-12">
													<button type="button"
															class="btn btn-success col-12 rounded-3 py-3 text-white fs-3"
															@click="this.setConfirmModeration()">
														Подтвердить
													</button>
												</div>
												<div v-else-if="!this.need_approve_moderation && this.item.object.services.is_moderated" class="d-flex col-12">
												<span class="alert alert-info col-12 mb-0 rounded-3 px-20 text-center">
													<img :src="require('@/assets/icons/info-blue-icon.svg')" alt="Info" class="d-inline-flex m-auto me-2" width="14" height="14" />
													Модерация подтверждена
												</span>
												</div>
											</div>
											<div v-if="(!this.user_can.moderate_adverts && this.user_can.add_to_moderation) && !this.item.object.services.is_moderated && this.moderation_status == 'pending'" class="d-flex pt-2 col-12">
												<div class="row col">
													<div class="d-flex col-12">
													<span class="alert alert-info col-12 mb-0 rounded-3 px-20 text-center">
														<img :src="require('@/assets/icons/info-blue-icon.svg')" alt="Info" class="d-inline-flex m-auto me-2" width="14" height="14" />
														Отправлен на модерацию
													</span>
													</div>
												</div>
											</div>
											<div v-else-if="(!this.user_can.moderate_adverts && this.user_can.add_to_moderation) && !this.item.object.services.is_moderated" class="d-flex pt-2 col-12">
												<div class="row">

													<SelectBox inputId="adverts_moderators"
															   inputName="adverts_moderators"
															   ref="adverts_moderators"
															   inputLabel="Ответственные:"
															   :inputOptions="this.moderators_list"
															   :inputValue="(!this.isEmpty(this.adverts_moderators)) ? this.adverts_moderators : []"
															   inputLabelClass="text-font-secondary mb-3"
															   inputClass="rounded-3 p-2"
															   inputWrapClass="mb-20"
															   inputSearch="true"
															   inputMultiple="true"
															   @setValue="(value) => (!this.isEmpty(value)) ? this.adverts_moderators = value : this.adverts_moderators = []" />

													<TextArea inputId="adverts_comment"
															  inputLabel="Комментарий:"
															  inputName="adverts_comment"
															  ref="adverts_comment"
															  inputLabelClass="text-font-secondary mb-3"
															  :inputValue="(!this.isEmpty(this.adverts_comment)) ? this.adverts_comment : null"
															  inputClass="rounded-3 p-2"
															  inputWrapClass="mb-20"
															  @setValue="(value) => this.adverts_comment = value" />

													<div class="mb-0">
														<button type="button"
																class="btn btn-success col-12 rounded-3 py-3 text-white fs-3"
																:disabled="this.isEmpty(this.adverts_moderators) || !this.is_adverts_setup"
																@click="this.addObjectToModeration()">
															Отправить на модерацию
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-if="!this.isEmpty(this.stage.after_close.add_contract)" class="row align-items-center flex-row mb-2">
							<div class="row-fluid mb-2">
								<div class="col col-12">
									<label for="contracts_list" class="form-label col text-dark-gray">
										Договоры:
									</label>
								</div>
								<div id="contracts_list" class="col col-12">
									<ul v-if="!this.isEmpty(this.item.contracts)"
										class="list-unstyled d-flex flex-column">
										<li v-for="contract in this.item.contracts" :key="contract.id"
											class="d-inline-block mb-2">
											<a href="javascript:{}"
											   class="link fs-4"
											   data-bs-toggle="modal"
											   data-bs-target="#addEditContractModal2"
											   :data-bs-contract-id="contract.id"
											   :data-bs-source-id="this.contracts_source_id"
											   :data-bs-section="this.contracts_section"
											   :data-bs-simulate="true">
												<img :src="require('@/assets/icons/pen-icon.svg')" alt="Edit" class="d-inline-flex ms-0 me-1" width="14" height="14" style="margin-top: 1px;">
												{{ this.getContractType(contract.type) }}
												"{{ contract.number }}"
												с {{ this.formatDateTime( contract.date_start, 'ru', 'DD.MM.YYYY') }}
												по {{ this.formatDateTime(contract.date_end, 'ru', 'DD.MM.YYYY') }}
											</a>
										</li>
									</ul>
								</div>

								<a href="javascript:{}"
								   class="btn d-flex align-items-center p-0 mt-1 mb-2 me-20 rounded-pill"
								   style="min-width: 150px;"
								   data-bs-toggle="modal"
								   data-bs-target="#addEditContractModal2"
								   :data-bs-contract-id="null"
								   :data-bs-source-id="this.contracts_source_id"
								   :data-bs-section="this.contracts_section"
								   :data-bs-simulate="true"
								   @click="this.add_contract_is_open = true">
									<span class=" d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
										  style="width: 40px; height: 40px;">
										<img :src="require('@/assets/icons/add-icon.svg')"
											 class="d-block"
											 alt="Добавить"
											 width="14"
											 height="14">
									</span>
									<span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить договор</span>
								</a>
							</div>
						</div>

						<SelectBox v-if="!this.isEmpty(this.stage.after_close.add_activity)" inputId="tags"
								   inputName="tags"
								   ref="tags"
								   inputLabel="Теги:"
								   :inputOptions="this.tags_list"
								   :inputValue="this.item.tags"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputMultiple="true"
								   inputSearch="true"
								   @setValue="(value) => this.item.tags = value" />
					</div>

				</div>

				<div class="modal-footer border-0 p-1">

					<div v-if="this.is_last_stage || (this.is_prev_steps_multiply && !this.isEmpty(this.stage.prev_steps))"
						 :disabled="!this.isEmpty(this.errors)"
						 class="dropdown dropup col-12">
						<button
							class="btn btn-outline-danger w-100 mx-0 rounded-3 py-2 fs-3 align-content-center icon icon-color-white"
							type="button"
							id="prevStepsDropdown"
							data-bs-toggle="dropdown"
							:disabled="!this.isEmpty(this.errors) || this.is_prev_stage_loading"
							aria-expanded="false">
							Предыдущие этапы
						</button>
						<ul class="dropdown-menu w-100 dropdown-menu-lg-end"
							aria-labelledby="prevStepsDropdown">
							<li><h5 class="dropdown-header">Выберите этап:</h5></li>
							<li v-for="(step, index) of this.stage.prev_steps"
								:key="index">
								<a class="dropdown-item py-3"
								   href="javascript:{}"
								   @click="this.setPrevStage(this.stage.prev_step_id, step.id)">
									<img :src="require('@/assets/icons/back-icon.svg')" alt="Предыдущий этап" width="18" height="18" class="me-2 my-auto" />
									{{ step.name }}
								</a>
							</li>
						</ul>
					</div>
					<button v-else-if="!this.isEmpty(this.prev_step)" type="button"
							:disabled="!this.isEmpty(this.errors) || this.is_prev_stage_loading"
							class="btn btn-danger col-12 rounded-3 py-2 fs-3 text-white align-content-center icon icon-color-white"
							@click="this.setPrevStage(this.stage.prev_step_id, this.prev_step[0].id)">
						<img :src="require('@/assets/icons/back-icon.svg')" alt="Предыдущий этап" width="18" height="18" class="ms-2 my-auto" />
						Предыдущий этап «{{ this.prev_step[0].name }}»
					</button>

					<button v-if="this.is_last_stage && this.isEmpty(this.stage)" type="button"
							class="btn btn-outline-secondary col-12 rounded-3 py-2 fs-3 align-content-center"
							data-bs-dismiss="modal">
						Ок
					</button>
					<button v-else type="button"
							class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
							:disabled="!this.isEmpty(this.errors)"
							@click="this.closeStage()">
						Закрыть этап
					</button>
				</div>
			</div>
		</div>
	</div>

	<NewTaskModal ref="stageTaskModal"
				  modalId="changeStage"
				  :eventSection="this.new_task_section"
				  :eventType="this.new_task_event_type"
				  :clientId="this.new_task_client_id"
				  :objectId="this.new_task_object_id"
				  :requisitionId="this.new_task_requisition_id"
				  @newTasksClosed="this.newEventReset()"
				  @updateTasksList="(list) => this.updateTasksList(list)" />

	<AddEditContractModal :modalId="'addEditContractModal2'"
						  :contractSection="this.contracts_section"
						  :simulateAdd="true"
						  @contractAdded="(data) => {

							this.reStoreForm('changeStageModal');

							if (!this.isEmpty(data.contract)) {
								this.item.contracts.push(data.contract);
								this.storeForm();
							}

							let modal = this.getModal('addEditContractModal2');
							if (modal)
								modal.hide();

							this.showModal();
						  }"
						  @modalClosed="this.reStoreForm('changeStageModal') && this.showModal()" />

	<AdvertDaysModal @updateObject="(object_id) => {
						this.reStoreForm('changeStageModal');
						this.getObject(object_id).then(data => {
							this.item.object = data
						});
						this.showModal();
					 }"
					 @modalClosed="this.reStoreForm('changeStageModal') && this.showModal()"
					 :isModeration="this.is_adverts_moderation" />

</template>

<script>
    import TextArea from '@/components/inputs/TextArea'
    import InputSelect from '@/components/inputs/InputSelect'
	import CommonService from "@/services/CommonService";
	import api from "@/api";
	import NewTaskModal from "@/components/modals/NewTaskModal";
	import InputText from "@/components/inputs/InputText";
	import DateTimePicker from "@/components/inputs/DateTimePicker";
	import InputFile from "@/components/inputs/InputFile";
	import Loader from "@/components/common/Loader";
	import SelectBox from "@/components/inputs/SelectBox";
	import Switcher from "@/components/inputs/Switcher";
	import * as bootstrap from "bootstrap";
	import AddEditContractModal from "@/components/modals/AddEditContractModal";
	import AdvertDaysModal from "@/components/modals/AdvertDaysModal";
	//import SelectBox from "@/components/inputs/SelectBox";

    export default {
		name: 'ChangeStageModal',
        components: {
			AdvertDaysModal,
			AddEditContractModal,
			Switcher,
			SelectBox,
			Loader,
			//SelectBox,
			InputFile,
			DateTimePicker,
			InputText,
			NewTaskModal,
            TextArea,
            InputSelect
        },
        props: {
            fromSection: { type: String }
        },
		data() {
			return {
				errors: [],
				all_errors: [],
				object_id: 0,
				client_id: 0,
				requisition_id: 0,
				funnel_id: 0,
				stage_id: null,
				step_id: null,
				tags_list: [],
				contract_types: [],
				add_advert_is_open: false,
				add_contract_is_open: false,
				item: {
					tags: [],
					contracts: [],
					object: {},
				},
				need_show_moderation: 1,
				adverts_moderators: [],
				adverts_comment: '',
				moderators_list: [],
				moderation_errors: [],
				stage: {},
				is_last_stage: false,
				is_prev_stage_loading: false,
				new_task_section: null,
				new_task_event_type: null,
				new_task_client_id: null,
				new_task_object_id: null,
				new_task_requisition_id: null,
				new_task_field_name: null,
				fields: [],
				linking_steps: [],
				tasks_list: [],
				doers_list: [],
				denial_work_list: [],
				client: {
					denial_work: null,
					comment: '',
					is_deal_done: false,
					commission: null,
				},
			}
		},
		methods: {
			getModal(modal_id) {
				return CommonService.getModal(modal_id);
			},
			showModal() {
				let modalEl = this.$refs.changeStageModal;
				let modal = new bootstrap.Modal(modalEl);
				modal.show();
			},
			storeForm() {
				let data = {
					object_id: this.object_id,
					client_id: this.client_id,
					requisition_id: this.requisition_id,
					funnel_id: this.funnel_id,
					item: this.item,
					stage: this.stage,
					fields: this.fields,
					linking_steps: this.linking_steps,
					is_last_stage: this.is_last_stage,
				};

				CommonService.log('debug', 'setFormsState', data);
				this.$store.commit('setFormsState', { 'changeStageModal': data });
			},
			resetStoreForm() {
				this.$store.commit('resetFormsState', 'changeStageModal');
			},
			reStoreForm() {

				let stored = this.$store.getters.formState['changeStageModal'];

				CommonService.log('debug', 'reStoreForm()', stored);

				if (!this.isEmpty(stored)) {

					if (!this.isEmpty(stored.object_id))
						this.object_id = stored.object_id;

					if (!this.isEmpty(stored.client_id))
						this.client_id = stored.client_id;

					if (!this.isEmpty(stored.requisition_id))
						this.requisition_id = stored.requisition_id;

					if (!this.isEmpty(stored.funnel_id))
						this.funnel_id = stored.funnel_id;

					if (!this.isEmpty(stored.stage))
						this.stage = stored.stage;

					if (!this.isEmpty(stored.item))
						this.item = stored.item;

					if (!this.isEmpty(stored.fields))
						this.fields = stored.fields;

					if (!this.isEmpty(stored.linking_steps))
						this.linking_steps = stored.linking_steps;

					if (!this.isEmpty(stored.is_last_stage))
						this.is_last_stage = stored.is_last_stage;

					this.add_advert_is_open = false;
					this.add_contract_is_open = false;
				}

				this.resetStoreForm();
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			formatDateTime(datetime, locale, format) {
				return CommonService.formatDateTime(datetime, locale, format);
			},
			getModerators(object_id, use_in_advert) {

				let _this = this;
				_this.item.object.use_in_advert = use_in_advert;

				setTimeout(() => {
					if (use_in_advert)
						this.need_show_moderation = 0;
					else
						this.need_show_moderation = 1
				}, 1000);

				if (use_in_advert == 0) {
					if (confirm('Вы действительно желаете снять объект с модерации?')) {
						_this.deleteObjectFromModeration();
					}
				}

				if (!this.inArray(object_id, this.in_moderation_list)) {
					this.in_moderation_list.push(object_id);
				} else {

					let index = this.in_moderation_list.indexOf(object_id);
					if (index != -1)
						this.in_moderation_list.slice(index, 1);

				}

				console.info(this.in_moderation_list);

				return api.get('/adverts/moderators', {
					params: {
						object_id: object_id,
						section: 'adverts'
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getModerators()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, employee]) => {
								list.push({
									value: employee.id.toString(),
									name: (employee.department !== 'null') ? employee.name : employee.name + ' (' + employee.department + ')'
								});
							});
						}

						_this.moderators_list = list
						return list;
					} else {
						return {};
					}
				}).catch(function (error) {

					CommonService.log('error', 'getModerators()::axios', error);

				});
			},
			getDoers(include_ids) {
				return api.get('/common/employees', {
					params: {
						add_groups: true,
						include_ids: include_ids,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getDoers()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						let groups = (response.data.groups) ? response.data.groups : [];
						//console.log(groups);

						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, employee]) => {

								if (employee.group_id) {

									let group_id = employee.group_id;
									if (groups[group_id]) {

										list.push({
											value: '#',
											name: '<b>' + groups[group_id] + '</b>'
										});

										delete groups[group_id];
									}
								}

								let department_name = (employee.department)  ? ' (' + employee.department + ')' : '';
								list.push({
									value: employee.id,
									name: (employee.group_id) ? '&nbsp;&nbsp;-&nbsp;' + employee.name : employee.name + department_name
								});
							});
						}

						this.$store.commit('setEmployeesList', list);
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getDoers()::axios', error);

				});
			},
			getList(section) {
				if (typeof section !== "undefined") {
					return api.get('/common/list', {
						params: {
							section: section
						}
					})
						.then((response) => {

							CommonService.log('debug', 'getList()::axios', {response: response.data});

							if (response.status == 200 && response.data.success) {
								let list = [];
								Object.entries(response.data.list).forEach(([key, option]) => {
									list.push({
										value: option.id,
										name: option.name
									});
								});

								return list;
							} else {
								return {};
							}

						}).catch(function (error) {

							CommonService.log('error', 'getList()::axios', error);

						});
				}
			},
			newTaskEvent(field_name, task_type) {

				if (typeof task_type !== "undefined") {

					let section = null;
					if (!this.isEmpty(this.client_id))
						section = 'clients';

					if (!this.isEmpty(this.requisition_id))
						section = 'requisitions';

					this.new_task_section = section;
					this.new_task_event_type = task_type;
					this.new_task_client_id = this.client_id;
					this.new_task_object_id = this.object_id;
					this.new_task_requisition_id = this.requisition_id;
					this.new_task_field_name = field_name;

					if (process.env.NODE_ENV == "development")
						console.debug('newTaskEvent()', {
							section: this.new_task_section,
							event_type: this.new_task_event_type,
							client_id: this.new_task_client_id,
							object_id: this.new_task_object_id,
							requisition_id: this.new_task_requisition_id,
						});


					this.$refs.stageTaskModal.showModal();

				} else {

					CommonService.log('debug', 'newTaskEvent()', task_type);

				}
			},
			newEventReset() {

				this.new_task_section = null;
				this.new_task_event_type = null;
				this.new_task_client_id = null;
				this.new_task_object_id = null;
				this.new_task_requisition_id = null;
				this.new_task_field_name = null;

				CommonService.log('debug', 'newEventReset()');
			},
			updateTasksList(list) {

				let modal = CommonService.getModal('newTaskModal_changeStage');
				if (modal) {
					modal.hide();
				}

				CommonService.log('debug', 'updateTasksList()', {for_field_name: this.new_task_field_name, tasks: list});

				this.stage.fields_list.forEach((field, index) => {
					if (field.type == 'checkbox' && this.new_task_field_name == field.name) {

						if (this.isEmpty(field.value))
							field.value = [];

						list.forEach((task, index2) => {

							if (task.is_new) {

								let name = 'Задача';
								if (task.type == 'call')
									name = 'Звонок';
								else if (task.type == 'meet' || task.type == 'meeting')
									name = 'Встреча';
								else if (task.type == 'show' || task.type == 'showing')
									name = 'Показ';
								else if (task.type == 'deal')
									name = 'Сделка';

								/*field.value = {...[{
										name: name,
										type: task.type,
										date: task.event.schedule_date_string ?? task.created_at,
									}], ...field.value
								};*/

								field.value.push({
									name: name,
									type: task.type,
									date: task.event.schedule_date_string ?? task.created_at,
								});
							}
						});

						this.new_task_field_name = null;
					}
				});
			},
			async getClientStages(funnel_id, client_id) {

				return api.get('/common/stages', {
					params: {
						mode: 'only_next', // 'all' - все этапы, 'only_current' - текущий этап, 'only_next' - следующий этап
						funnel_id: funnel_id,
						client_id: client_id
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getClientStages()::axios', {response: response.data});

					if (response.status == 200 && response.data.success)
						return response.data.stages;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getClientStages()::axios', error);

				});
			},
			async getRequisitionStages(funnel_id, requisition_id) {

				return api.get('/common/stages', {
					params: {
						mode: 'only_next', // 'all' - все этапы, 'only_current' - текущий этап, 'only_next' - следующий этап
						funnel_id: funnel_id,
						requisition_id: requisition_id
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getRequisitionStages()::axios', {response: response.data});

					if (response.status == 200 && response.data.success)
						return response.data.stages;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getRequisitionStages()::axios', error);

				});
			},
			getFunnels(section) {
				return api.get('/common/all-funnels', {
					params: {
						section: section,
						only_active: true
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getFunnels()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						return response.data.list;
					}

					return null;
				}).catch(function (error) {
					CommonService.log('error', 'getFunnels()::axios', error);
					return null;
				});
			},
			getClientTasks(client_id) {
				return api.get('/common/tasks', {
					params: {
						client_id: client_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getClientTasks()::axios', {response: response.data});

					if (response.status == 200 && response.data.success)
						return this.tasks_list = response.data.tasks;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getClientTasks()::axios', error);

				});
			},
			getRequisitionTasks(requisition_id) {
				return api.get('/common/tasks', {
					params: {
						requisition_id: requisition_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getRequisitionTasks()::axios', {response: response.data});

					if (response.status == 200 && response.data.success)
						return this.tasks_list = response.data.tasks;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getRequisitionTasks()::axios', error);

				});
			},
			getPrevStage(section, funnel_id, stage_id, prev_step_id) {
				this.is_prev_stage_loading = true;
				if (!this.isEmpty(this.$store.getters.funnelsData)) {

					let prev_stage = null;
					let funnel = this.$store.getters.funnelsData.filter((item) => item.id == funnel_id);
					if (!this.isEmpty(funnel[0].steps)) {
						let current_step = funnel[0].steps.filter((item) => item.id == stage_id);

						console.info('getPrevStage()::current_step', current_step);

						if (typeof current_step[0] !== "undefined") {

							if (funnel_id == 0) {
								if (!this.isEmpty(current_step)) {
									this.stage.prev_steps = [];
									current_step.forEach(step => {
										this.stage.prev_steps.push(step);
									});

									if (this.stage.prev_steps.length > 1)
										this.is_prev_steps_multiply = true;

								}
							} else {
								if (!this.isEmpty(current_step[0].prev_steps)) {

									if (!this.isEmpty(this.stage.prev_step_id)) {
										let prev_steps = current_step[0].prev_steps;
										prev_stage = prev_steps.filter((item) => item.id == prev_step_id);
										this.stage.prev_steps = prev_stage;
									} else {
										prev_stage = current_step[0].prev_steps;
										this.stage.prev_steps = prev_stage;
									}

									console.info('getPrevStage()::prev_steps', this.stage.prev_steps);

									if (this.stage.prev_steps.length > 1) {
										this.is_prev_steps_multiply = true;
									}

									this.is_prev_stage_loading = false;
									return prev_stage;
								}
							}
						}
					}
				} else {
					return this.getFunnels(section).then((list) => {
						let prev_stage = null;
						let funnel = list.filter((item) => item.id == funnel_id);
						if (!this.isEmpty(funnel[0].steps)) {
							let current_step = funnel[0].steps.filter((item) => item.id == stage_id);

							if (funnel_id == 0) {
								if (!this.isEmpty(current_step)) {
									this.stage.prev_steps = [];
									current_step.forEach(step => {
										this.stage.prev_steps.push(step);
									});

									if (this.stage.prev_steps.length > 1)
										this.is_prev_steps_multiply = true;

								}
							} else {
								if (!this.isEmpty(current_step[0].prev_steps)) {

									if (!this.isEmpty(this.stage.prev_step_id)) {
										let prev_steps = current_step[0].prev_steps;
										prev_stage = prev_steps.filter((item) => item.id == prev_step_id);
										this.stage.prev_steps = prev_stage;
									} else {
										prev_stage = current_step[0].prev_steps;
										this.stage.prev_steps = prev_stage;
									}

									if (this.stage.prev_steps.length > 1)
										this.is_prev_steps_multiply = true;

									this.is_prev_stage_loading = false;
									return prev_stage;
								}
							}
						}

						return null;
					});
				}
			},
			getTypes(section) {
				if (typeof section !== "undefined") {
					return api.get('/common/types', {
						params: {
							section: section
						}
					})
						.then((response) => {

							CommonService.log('debug', 'getTypes()::axios', {response: response.data});

							if (response.status == 200 && response.data.success) {
								let list = [];
								Object.entries(response.data.list).forEach(([key, option]) => {
									list.push({
										value: option.id,
										name: option.name
									});
								});

								return list;
							} else {
								return {};
							}

						}).catch(function (error) {

							CommonService.log('error', 'getTypes()::axios', error);

						});
				}
			},
			getTags(section) {
				return api.get('/common/tags', {
					params: {
						with_managers: true
					}
				}).then((response) => {

					CommonService.log('debug', 'getTags()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							if (section == 'requisitions') {
								list.push({
									id: option.id,
									name: option.name
								});
							} else {
								list.push({
									value: option.id,
									name: option.name
								});
							}
						});
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getTags()::axios', error);

				});
			},
			sendObjectToAdverts(object_id, value, event) {

				CommonService.log('log', {object_id: object_id, service: 'global', status: this.item.object.use_in_advert});

				let to_send = 1
				if (this.item.object.use_in_advert && !value) {
					if (this.user_can.moderate_adverts && !this.isEmpty(this.item.moderation) && !this.item.is_moderated) {
						if (confirm('Вы действительно желаете снять объект с модерации?')) {
							this.deleteObjectFromModeration();
						}
						to_send = 0;
					} else {
						if (!confirm('Убрать объект из рекламы? Если показ в рекламе уже был начат, то все ротации по объекту будут остановлены.')) {
							to_send = 0;
						}
					}
				}

				if (to_send) {
					api.put('/adverts/set', {
						object_id: object_id,
						service: 'global',
						current: this.item.object.use_in_advert
					}).then((response) => {
						CommonService.log('debug', 'sendObjectToAdverts()::axios', {response});

						if (response.status == 200 && response.data.success)
							this.item.object.use_in_advert = response.data.state;

						this.$emit('updateObject', object_id);

					}).catch(function (error) {

						this.item.object.use_in_advert = !this.item.object.use_in_advert;

						CommonService.log('error', 'sendObjectToAdverts()::axios', error);

						this.$emit('updateObject', object_id);
						this.$emit('sendObjectToAdverts', object_id);
					});
				}
			},
			addRemoveObjectAdverts(object_id, service, event) {

				var trigger = event.currentTarget;
				trigger.classList.add('disable');

				if (!this.isEmpty(this.item.object.services.advertised)) {

					let state = this.item.object.services.advertised[service];
					CommonService.log('info', 'addRemoveObjectAdverts()', {object_id: object_id, service: service, state: state});
					this.$emit('setObjectAdvertState', {object_id: object_id, service: service, state: !state});

					api.put('/adverts/set', {
						object_id: object_id,
						service: service,
						current: state
					}).then((response) => {
						CommonService.log('debug', 'addRemoveObjectAdverts()::axios', {response});

						if (response.status == 200 && response.data.success) {
							this.item.object.services.advertised[service] = response.data.state;
							this.$emit('setObjectAdvertState', {object_id: object_id, service: service, state: response.data.state});
						}

						trigger.classList.remove('disable');
						this.$emit('updateObject', object_id);
					}).catch(function (error) {

						this.item.object.services.advertised[service] = !this.item.object.services.advertised[service];
						trigger.classList.remove('disable');
						CommonService.log('error', 'addRemoveObjectAdverts()::axios', error);

						this.$emit('updateObject', object_id);
						this.$emit('addRemoveObjectAdverts', object_id);
					});
				}
			},
			getContractType(type_id) {

				let contract_type = false;
				if (typeof type_id !== "undefined" && !this.isEmpty(this.contract_types)) {
					this.contract_types.forEach((type) => {
						if (type.value == type_id)
							contract_type = type.name;
					});
				}

				return contract_type;
			},
			getContractsByRef(section, reference_id) {

				return api.get('/contracts', {
					params: {
						section: section,
						reference_id: reference_id,
					}
				}).then((response) => {

					CommonService.log('debug', 'getContracts()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, item]) => {
							list.push(item);
						});

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getContracts()::axios', error);

				});
			},
			getObject(object_id) {

				this.emitter.emit("global.startGetObject", { ref: 'ChangeStageModal::getObject()', object_id });

				return api.get('/object', {
					params: {
						object_id: object_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getObject()::axios', {response: response.data});

					this.emitter.emit("global.endGetObject", { response: response });

					if (response.status == 200 && response.data.success)
						return response.data.item;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getObject()::axios', error);
					this.emitter.emit("global.endGetObject", { error: error });

				});
			},
			buildTagsList(tags, tags_list) {
				let list = CommonService.resetProxy(tags_list);
				if (tags && list) {
					list.find((item, i) => {
						if (tags.indexOf(item.id) !== -1) {
							list[i].isChecked = true;
						} else {
							list[i].isChecked = false;
						}
					});
				}

				return list;
			},
			checkAfterCloseProps() {

				/*console.info(this.stage.after_close);
				console.info(this.client_id);
				console.info(this.object_id);
				console.info(this.requisition_id);
				console.info(this.fromSection);*/

				if (!this.isEmpty(this.stage.after_close)) {

					if (this.stage.after_close.add_activity) {
						this.getTags(this.section).then(data => {
							this.tags_list = this.sortArray(data)
						});


						if (!this.isEmpty(this.client_id)) {
							this.getClient(this.client_id).then(list => this.item.tags = list.tags);
						} else if (!this.isEmpty(this.requisition_id)) {
							this.getRequisition(this.requisition_id).then(list => this.item.tags = list.tags);
						}
					}

					if (this.stage.after_close.add_contract) {

						this.getTypes('contract_types').then(data => {
							this.contract_types = data
						});

						if (!this.isEmpty(this.client_id))
							this.getContractsByRef('clients', this.client_id).then(list => { this.item.contracts = list });
						else if (!this.isEmpty(this.requisition_id))
							this.getContractsByRef('requisitions', this.requisition_id).then(list => { this.item.contracts = list });

					}

					if (this.stage.after_close.use_in_advert_turn_off && !this.isEmpty(this.object_id)) {
						this.getObject(this.object_id).then(data => {
							this.item.object = data
						});
					}
				}
			},
			async getClient(client_id) {
				return api.get('/client', {
					params: {
						client_id: client_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getClient()::axios', {response: response.data});

					if (response.status == 200 && response.data.success)
						return response.data.item;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getClient()::axios', error);

				});
			},
			async getRequisition(requisition_id) {
				return api.get('/requisition', {
					params: {
						requisition_id: requisition_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getRequisition()::axios', {response: response.data});

					if (response.status == 200 && response.data.success)
						return response.data.item;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getRequisition()::axios', error);

				});
			},
			getCurrentStage(section, funnel_id, source_id) {

				if (section == 'clients') {
					this.getClientStages(funnel_id, source_id).then((stages) => {

						if (stages.length == 0)
							this.is_last_stage = true;

						this.stage = stages;

						if (this.stage.is_close_after === true && !this.isEmpty(this.client_id)) {
							this.getList('denial_work').then(data => { this.denial_work_list = data });
						}

						// Доп. действия при закрытии этапа
						if (!this.isEmpty(this.stage.after_close)) {
							this.checkAfterCloseProps();
						}

						CommonService.log('debug', 'getCurrentStage()', this.stage);

						this.getPrevStage(section, funnel_id, this.stage.id, this.stage.prev_step_id);

					});
				} else if (section == 'requisitions') {
					this.getRequisitionStages(funnel_id, source_id).then((stages) => {

						this.stage = stages;

						/*if (this.stage.is_close_after === true && !this.isEmpty(this.client_id)) {
							this.getList('denial_work').then(data => { this.denial_work_list = data });
						}*/

						// Доп. действия при закрытии этапа
						if (!this.isEmpty(this.stage.after_close))
							this.checkAfterCloseProps();

						CommonService.log('debug', 'getCurrentStage()', this.stage);

						this.getPrevStage(section, funnel_id, this.stage.id, this.stage.prev_step_id);

					});
				}
			},
			removeFile(prop_name, file) {

				let section = this.section;
				let params = {};

				if (typeof section !== "undefined") {

					params.section = section;

					if (this.funnel_id)
						params.funnel_id = this.funnel_id;

					if (this.client_id)
						params.client_id = this.client_id;

					if (this.requisition_id)
						params.requisition_id = this.requisition_id;

					if (this.object_id)
						params.object_id = this.object_id;

				}

				if (typeof file !== "undefined")
					params.filename = file.name;

				console.debug('removeFile()::axios', {
					params: params
				});

				let _this = this;
				api.delete('/common/file', {
					params: params
				}).then((response) => {

					if (process.env.NODE_ENV == "development")
						console.debug('removeFile()::axios', {
							response: response.data
						});

					if (response.status == 200 && response.data.success) {
						if (!_this.isEmpty(_this.stage.fields_list)) {
							_this.stage.fields_list.forEach((field, index) => {
								if (field.type && field.name && field.value) {
									if (field.type == 'file' && field.name == prop_name) {
										field.value.forEach((item, index2) => {
											if (item.name == params.filename) {
												field.value.splice(index2, 1);
											}
										});
									}
								}
							});
						}
					}
				}).catch(function (error) {

					if (!_this.isEmpty(_this.stage.fields_list)) {
						_this.stage.fields_list.forEach((field, index) => {
							if (field.type && field.name && field.value) {
								if (field.type == 'file' && field.name == prop_name) {
									field.value.forEach((item, index2) => {
										if (item.name == params.filename) {
											field.value.splice(index2, 1);
										}
									});
								}
							}
						});
					}

					CommonService.log('error', 'removeFile()::axios', error);

				});
			},
			uploadFile(prop_name, value) {

				let uploadProgress = 0;
				let uploadReadyState = false;

				let section = this.section;
				let data = new FormData();

				if (typeof section !== "undefined" && value.files.length) {
					for (let file of value.files) {
						data.append(section + '[]', file);
					}

					data.append('section', section);

					if (this.funnel_id)
						data.append('funnel_id', this.funnel_id);

					if (this.client_id)
						data.append('client_id', this.client_id);

					if (this.requisition_id)
						data.append('requisition_id', this.requisition_id);

					if (this.object_id)
						data.append('object_id', this.object_id);

					if (this.stage.id)
						data.append('stage_id', this.stage.id);

				}

				CommonService.log('debug', 'uploadFile()::data', data.entries());

				api.post('/common/upload', data, {
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					timeout: 180000,
					onUploadProgress: progressEvent => {

						uploadProgress = Math.round((progressEvent.loaded/progressEvent.total)*100);

						if (process.env.NODE_ENV == "development")
							console.debug('uploadFile()::onUploadProgress', {
								progress: uploadProgress
							});

						if (progressEvent.loaded === progressEvent.total) {

							uploadReadyState = true;
							uploadProgress = 0;

							if (process.env.NODE_ENV == "development")
								console.debug('uploadFile()::onUploadProgress', {
									progress: 'COMPLETED!'
								});
						}
					}
				}).then((response) => {

					if (process.env.NODE_ENV == "development")
						console.debug('uploadFile()::axios', {
							response: response.data
						});

					if (response.status == 200 && response.data.success) {

						uploadReadyState = true;
						uploadProgress = 0;
						if (response.data.files.length) {

							let files = response.data.files;
							if (!this.isEmpty(files)) {
								this.stage.fields_list.forEach((field, index) => {
									if (field.name == prop_name && field.type == 'file') {

										if (!field.value || typeof field.value !== "object")
											field.value = [];

										files.forEach((file, index) => {
											field.value.push({
												"guid": file.guid,
												"name": file.filename,
												"type": file.extension,
												"is_upload": true,
												"path": file.path
											});
										});
									}
								});
							}

							this.$emit('onFileUploaded', files, response.data.section);
						}
					}

				}).catch(function (error) {

					uploadReadyState = true;
					uploadProgress = 0;

					CommonService.log('error', 'uploadFile()::axios', error);

				});

				CommonService.log('debug', 'uploadFile()', {variable: prop_name, filename: value.name, files: value.files});

			},
			validateAll() {

				this.all_errors = [];

				if (!this.isEmpty(this.stage.fields_list)) {
					this.stage.fields_list.forEach((field, index) => {
						let label = field.label;
						let name = field.name;
						let value = field.value;

						if (field.is_required) {
							if (this.isEmpty(value)) {
								this.all_errors.push('Поле `' + label + '` обязательно к заполнению!');
							} else {
								if (value.toString() == '0') {
									this.all_errors.push('Поле `' + label + '` обязательно к заполнению!');
								}
							}
						}
					});

					if (this.stage.is_close_after === true) {

						if (this.isEmpty(this.client.denial_work)) {
							this.all_errors.push('Поле `Причина закрытия` обязательно к заполнению!');
						}

						if (this.isEmpty(this.client.comment)) {
							this.all_errors.push('Поле `Комментарий` обязательно к заполнению!');
						}
					}
				}

				console.info(this.all_errors);
				console.info(this.stage.fields_list);

				if (this.isEmpty(this.all_errors))
					return true;

				return false;
			},



			setAndValidate(type, name, value, is_link=false) {

				let errors = [];
				let parent = null;
				let child = null;
				let parts = name.split('.', 2);
				if (parts.length == 2) {
					parent = parts[0];
					child = parts[1];
				}

				// Валидация значения
				if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {
					/*if ('invalid' in this.$refs[name])
						this.$refs[name].invalid = false;*/

					if (this.$refs[name].inputLabel) {
						let label = this.$refs[name].inputLabel.replace(/:+$/, '');
						if(value){
							if (this.$refs[name].required && value.toString() == '0')
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							else if (this.$refs[name].required && this.isEmpty(value))
								errors.push('Поле `' + label + '` обязательно к заполнению!');
						}

						if (!this.isEmpty(errors))
							this.all_errors = [this.all_errors, ...errors];

						this.errors[name] = {
							name: name,
							label: label,
							errors: errors
						};

						if (!this.isEmpty(errors)) {
							this.$refs[name].valid = false;
							this.$refs[name].invalid = errors.join('<br/>');
						} else {
							this.$refs[name].valid = true;
							this.$refs[name].invalid = false;
						}

					}
				}

				// Установка значения
				if(!is_link){
					if (parent && child) {
						if (!this.isEmpty(this.fields[parent])) {
							if (typeof (this.fields[parent][child]) !== "undefined") {
								this.fields[parent][child] = value;
								console.log(value);
								console.log(this.fields[parent][child]);
							} else {
								this.fields[parent] = {
									...{
										[child]: value
									},
									...this.fields[parent]
								};
							}
						} else {
							this.fields[parent] = {
								[child]: value
							};
						}
					} else if (parent == null && name) {
						this.fields[name] = value;
					} else {
						if (parent && child)
							this.fields[parent][child] = null;
						else
							this.fields[name] = null;
					}

					if (this.funnel_id > 0) {

						if (value && this.inArray(type, ['text', 'textarea', 'datetime', 'select', 'checkbox', 'number'])) {
							this.stage.fields_list.forEach((field, index) => {
								if (field.name == name) {
									field.value = value;
								}
							});
						}

						if (type == 'select' && value) {
							let next_step_id = null;
							let next_step_name = null;
							this.stage.next_steps_list.forEach((step, index) => {

								if (step.linked == value) {
									next_step_id = step.id;
									next_step_name = step.name;
								}
							});
							this.stage.next_step_id = next_step_id;
							this.stage.next_step_name = next_step_name;
						}
					}
				} else {
					if (parent && child) {
						if (!this.isEmpty(this.linking_steps[parent])) {
							if (typeof (this.linking_steps[parent][child]) !== "undefined") {
								this.linking_steps[parent][child] = value;
							} else {
								this.linking_steps[parent] = {
									...{
										[child]: value
									},
									...this.linking_steps[parent]
								};
							}
						} else {
							this.linking_steps[parent] = {
								[child]: value
							};
						}
					} else if (parent == null && name) {
						this.linking_steps[name] = value;
					} else {
						if (parent && child)
							this.linking_steps[parent][child] = null;
						else
							this.linking_steps[name] = null;
					}
				}


				CommonService.log('debug', 'setAndValidate()', this.stage.fields_list[name]);

			},
			setPrevStage(step_id, prev_step_id) {

				if (typeof step_id == "undefined")
					step_id = this.stage.id;

				if (typeof prev_step_id == "undefined")
					prev_step_id = this.stage.prev_step_id;


				CommonService.log('debug', 'setPrevStage()', { prev_step_id, step_id});

				let data = {
					section: this.section,
					funnel_id: this.funnel_id,
					client_id: this.client_id,
					requisition_id: this.requisition_id,
					step_id: step_id,
					prev_step_id: prev_step_id,
				};

				if (confirm('Вы действительно желаете открыть предыдущий этап?')) {
					api.post('/common/prev-stage', data)
						.then((response) => {

							CommonService.log('debug', 'setPrevStage()::axios', {response: response.data});

							if (response.status == 200 && response.data.success) {
								this.$emit('setPrevStage', data);

								let modal = CommonService.getModal('changeStageModal')
								if (modal) {
									modal.hide();
								}
							}

						}).catch(function (error) {

						CommonService.log('error', 'setPrevStage()::axios', error);

					});
				}
			},
			closeClient() {
				return api.post('/clients/delete', {
					clients_ids: this.client_id,
					denial: this.client.denial_work,
					comment: this.client.comment,
					is_deal: this.client.is_deal_done,
					commission: this.client.commission,
				}).then((response) => {
					CommonService.log('debug', 'closeClient()::axios', {response});

					/*if (response.status == 200 && response.data.success) {

					}*/
				}).catch(function (error) {

					CommonService.log('error', 'initCloseClients()::axios', error);

				});
			},
			closeStage() {

				let use_in_advert = false;
				let services = {};

				if (!this.isEmpty(this.item.object)) {
					use_in_advert = this.item.object.use_in_advert;
					services = this.item.object.services;
				}

				let data = {
					section: this.section,
					funnel_id: this.funnel_id,
					client_id: this.client_id,
					requisition_id: this.requisition_id,
					stage: {...this.stage, after_close_do: {
						use_in_advert: use_in_advert,
						services: services,
						contracts: this.item.contracts,
						tags: this.item.tags,

					}},
				};

				CommonService.log('debug', 'closeStage()', data);

				if (this.validateAll()) {
					api.post('/common/close-stage', data)
					.then((response) => {
						console.log(response.data);
						CommonService.log('debug', 'closeStage()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							if (this.stage.is_close_after === true && !this.isEmpty(this.client_id)) {
								this.closeClient().then(() => {
									this.$emit('setStageAndClientClosed', data);
								});
							} else {
								this.$emit('setStageClosed', data);
							}

							this.stage_id = null;
							this.step_id = null;
							this.stage = {};
							this.item = {
								tags: [],
								contracts: [],
								object: {},
							};
							this.add_advert_is_open = false;
							this.add_contract_is_open = false;

							let modal = CommonService.getModal('changeStageModal')
							if (modal) {
								modal.hide();
							}
						}

					}).catch(function (error) {

						CommonService.log('error', 'closeStage()::axios', error);

					});
				}
			},
		},
		mounted() {
			let modal = document.getElementById('changeStageModal');
			if (modal && typeof modal !== "undefined") {

				let _this = this;
				modal.addEventListener('hidden.bs.modal', function (event) {

					if (_this.add_contract_is_open || _this.add_advert_is_open) {
						_this.storeForm();
					} else {
						_this.resetStoreForm();
					}

					_this.object_id = 0;
					_this.client_id = 0;
					_this.requisition_id = 0;
					_this.funnel_id = 0;
					_this.item = 0;
					/*_this.stage_id = null;
					_this.step_id = null;*/
					_this.item = {
						tags: [],
						contracts: [],
					};
					_this.stage = {};
					_this.errors = [];
					_this.is_last_stage = false;
					_this.all_errors = [];
					_this.emitter.emit("global.modalClose", modal);
				});

				modal.addEventListener('shown.bs.modal', function (event) {

					_this.all_errors = [];
					let target = event.relatedTarget;
					if (process.env.NODE_ENV == "development") {
						console.debug('changeStageModal::target', event);
					}

					if (typeof target !== "undefined") {

						if (!_this.isEmpty(target.getAttribute('data-bs-object-id')))
							_this.object_id = Number(target.getAttribute('data-bs-object-id'));

						if (!_this.isEmpty(target.getAttribute('data-bs-client-id')))
							_this.client_id = Number(target.getAttribute('data-bs-client-id'));

						if (!_this.isEmpty(target.getAttribute('data-bs-requisition-id')))
							_this.requisition_id = Number(target.getAttribute('data-bs-requisition-id'));

						if (!_this.isEmpty(target.getAttribute('data-bs-funnel-id')))
							_this.funnel_id = Number(target.getAttribute('data-bs-funnel-id'));

					}

					let stored = _this.$store.getters.formState['changeStageModal'];

					console.info(stored);

					if (!_this.isEmpty(stored)) {

						// Восстановление ранее введённых данных
						if (!this.add_advert_is_open && !this.add_contract_is_open)
							_this.reStoreForm();

					} else {

						// Доп. действия при закрытии этапа
						if (!_this.isEmpty(_this.stage.after_close))
							_this.checkAfterCloseProps();

						if (_this.funnel_id >= 0 && _this.client_id > 0) {
							_this.getCurrentStage('clients', _this.funnel_id, _this.client_id);
						} else if (_this.funnel_id >= 0 && _this.requisition_id > 0) {
							_this.getCurrentStage('requisitions', _this.funnel_id, _this.requisition_id);
						}
					}

					_this.emitter.on('objectLoaded', (item) => {
						_this.object_id = Number(item.id);
					});
					_this.emitter.on('clientLoaded', (item) => {
						_this.client_id = Number(item.id);
						_this.funnel_id = Number(item.funnel_id);
					});
					_this.emitter.on('requisitionLoaded', (item) => {
						_this.requisition_id = Number(item.id);
						_this.funnel_id = Number(item.funnel_id);
					});

					if (_this.funnel_id >= 0 && _this.client_id)
						_this.getClientTasks(_this.client_id).then((tasks) => this.tasks_list = tasks);

					if (_this.funnel_id >= 0 && _this.requisition_id)
						_this.getRequisitionTasks(_this.requisition_id).then((tasks) => this.tasks_list = tasks);

					if (!_this.isEmpty(_this.stage.doers))
						_this.getDoers(_this.stage.doers).then(data => { _this.doers_list = data });

					if (process.env.NODE_ENV == "development") {
						console.debug('changeStageModal::show.bs.modal', {
							funnel_id: _this.funnel_id,
							object_id: _this.object_id,
							client_id: _this.client_id,
							requisition_id: _this.requisition_id,
							section: _this.section,
							stage: _this.stage,
							item: _this.item,
						});
					}

					_this.emitter.emit("global.modalShown", modal);
				});
			}
		},
		watch: {
			all_errors(value, oldValue) {
				if (process.env.NODE_ENV == "development") {
					console.debug('⚠️ all_errors::watch', {value, oldValue});
				}
			},
			stage(value, oldValue) {
				if (this.funnel_id > 0 && !this.isEmpty(value.prev_steps)) {
					if (!this.is_prev_steps_multiply) {
						this.stage.prev_step = {
							id: value.prev_steps[0].id,
							name: value.prev_steps[0].name,
						};
					}
				}
			},
		},
		computed: {
			user_can() {
				return {
					add_to_adverts: this.$store.getters.userInfo.user_can.add_to_adverts && !this.item.object.is_archived && this.item.object.add_user_id > 0, // В рекламу
					add_to_moderation: this.$store.getters.userInfo.user_can.add_to_moderation && !this.item.object.is_archived && this.item.object.add_user_id > 0, // Отправка рекламы на модерацию
					advertise: {
						free: (this.$store.getters.userInfo.user_can.advertise.free),
						avito: (this.$store.getters.userInfo.user_can.advertise.avito),
						cian: (this.$store.getters.userInfo.user_can.advertise.cian),
						zipal: (this.$store.getters.userInfo.user_can.advertise.zipal),
						domclick: (this.$store.getters.userInfo.user_can.advertise.domclick),
						emls: (this.$store.getters.userInfo.user_can.advertise.emls),
						jcat: (this.$store.getters.userInfo.user_can.advertise.jcat),
						yandex: (this.$store.getters.userInfo.user_can.advertise.yandex),
						promotion: (this.$store.getters.userInfo.user_can.advertise.promotion),
					}
				};
			},
			section() {

				let section = null;

				if (!this.isEmpty(this.client_id) && this.client_id > 0)
					section = 'clients';

				if (!this.isEmpty(this.requisition_id) && this.requisition_id > 0)
					section = 'requisitions';

				if (!this.inArray(section, ['clients', 'requisitions']) && !this.isEmpty(this.object_id) && this.object_id > 0)
					section = 'objects';

				return section ?? this.$props.fromSection;
			},
			contracts_section() {

				if (!this.isEmpty(this.client_id) && this.client_id > 0)
					return 'clients';
				else if (!this.isEmpty(this.requisition_id) && this.requisition_id > 0)
					return 'requisitions';
				else if (!this.isEmpty(this.object_id) && this.object_id > 0)
					return 'objects';

				return null;
			},
			contracts_source_id() {

				if (this.contracts_section == 'clients')
					return this.client_id;
				else if (this.contracts_section == 'requisitions')
					return this.requisition_id;
				else if (this.contracts_section == 'objects')
					return this.object_id;

				return null;
			},
			prev_step() {

				if (!this.isEmpty(this.stage.prev_steps)) {
					//return this.stage.prev_steps[0].id;

					let prev_step = this.stage.prev_steps.filter(step => {
						return (step.id == this.stage.prev_step_id);
					});
					console.info(prev_step);
					return prev_step;
				}

				return null;
			},
			is_adverts_moderation() {
				return (this.$store.getters.userInfo.user_can.add_to_moderation) ? true : false;
			},
			is_prev_steps_multiply() {

				if (!this.isEmpty(this.stage.prev_steps))
					return (Object.entries(this.stage.prev_steps).length > 1);

				return false;
			},
			stage_name() {

				/*if (this.funnel_id !== 0)
					return this.stage.name;*/

				return this.stage.name;
			},
		},
	}
</script>
