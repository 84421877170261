<template>
    <div class="modal fade" id="setTagsModal" tabindex="-1" aria-labelledby="Добавить теги" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content p-3">
                <div class="modal-header d-flex align-items-center mb-20 border-0 p-1">
                    <span class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">Добавить теги</span>
                    <button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body d-flex flex-column p-1">
                    <div class="d-flex align-items-center border border-brd-primary rounded-3 p-10 mb-20">
                        <div class="text-nowrap-mask me-auto"
                            style="width: 75% !important; overflow: hidden;">
                            <span class="text-font-secondary" v-if="!this.checkedTags.length">Тэги</span>
                            <!-- список выбранных хар-к -->
                            <ul v-else
                                class="d-flex p-0 m-0">
                                <li  class="me-10"
									 v-for="(checkedTag, i) of this.checkedTags"
									 :key="'checkedTag_' + i">
                                    <button type="button"
											class="d-flex align-items-center btn bg-light-gray border border-brd-primary rounded-1 p-10"
											style="width: 90px; height: 40px; overflow: hidden;"
											@click="checkedTag.isChecked = false">
                                        <img :src="require('@/assets/icons/close-icon.svg')" class="d-block me-10" alt="Поиск" width="12" height="12">
                                        <span class="text-nowrap-mask">{{ checkedTag.name }}</span>
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <button v-if="this.checkedTags.length"
								type="button"
								aria-label="Поиск"
								class="d-flex align-items-center justify-content-center btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-3"
								style="width: 44px; height: 44px;"
								@click="this.list.forEach(tag => tag.isChecked = false)">
                            <img :src="require('@/assets/icons/close-icon.svg')" class="d-block" alt="Очистить" width="14" height="14">
                        </button>

                        <button type="button"
								class="d-flex align-items-center justify-content-center border-0 bg-transparent p-0"
								style="width: 44px; height: 44px;"
								aria-label="Выбрать"
								@click="isListShowed = !isListShowed">
                            <img :src="require('@/assets/icons/burger-icon.svg')" class="d-block" alt="Добавить" width="20" height="20">
                        </button>
                    </div>

                    <div class="" v-if="isListShowed">
                        <ul class="p-1 mb-20" style="height: 235px; overflow: hidden; overflow-y: scroll; padding-top: 6px !important;">
                            <li v-for="tag of this.list"
								:key="tag.id">
                                <div class="form-check me-auto mb-20">
                                    <input class="form-check-input rounded-1" type="checkbox" v-model="tag.isChecked" :id="tag.id">
                                    <label class="form-check-label" :for="tag.id">
                                        {{tag.name}}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>

					<button type="button"
							class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
							@click="setTags()">
						Сохранить
					</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import CommonService from "@/services/CommonService";
	import api from "@/api";

    export default {
		name: 'SetTagsModal',
		props: {
			tagsSection: {type: String},
			tagsSourceId: {type: Number},
			tagsList: {type: Array},
		},
        data() {
            return {
                isTagMenuShowed: false,
                isListShowed: true,
				section: (typeof this.$props.tagsSection !== "undefined") ? this.$props.tagsSection : "",
				source_id: (typeof this.$props.tagsSourceId !== "undefined") ? this.$props.tagsSourceId : null,
				list: (typeof this.$props.tagsList !== "undefined") ? this.$props.tagsList : []
            }
        },
		methods: {
			setTags: function () {
				let _this = this;
				let modal = CommonService.getModal('setTagsModal');
				let tags_list = _this.list;
				let params = {
					section: _this.section,
					source_id: _this.source_id,
					tags_list: tags_list
				};

				CommonService.log('debug', 'SetTagsModal::setTags()', params);

				api.put('/common/tags', params).then((response) => {

					CommonService.log('debug', 'setTags()::axios', {response});

					if (response.data.success && response.data.tags_ids.length) {
						this.$emit('saveSuccess', response.data.tags_ids, _this.source_id);
					}

				}).catch(function (error) {

					CommonService.log('error', 'setTags()::axios', error);

				});

				_this.$emit('saveTags', params);
				modal.hide();

			}
		},
		async mounted() {
			var _this = this;
			var modal = document.getElementById('setTagsModal');
			if (modal && typeof modal !== "undefined") {
				modal.addEventListener('show.bs.modal', function (event) {
					let target = event.relatedTarget

					let section = target.getAttribute('data-bs-section');
					if (typeof section !== "undefined") {
						_this.section = section;

						if (section == 'objects')
							_this.source_id = target.getAttribute('data-bs-object-id');
						else if (section == 'clients')
							_this.source_id = target.getAttribute('data-bs-client-id');
						else if (section == 'requisitions')
							_this.source_id = target.getAttribute('data-bs-requisition-id');

					}

					let tags_list = target.getAttribute('data-bs-tags-list');
					if (typeof tags_list !== "undefined") {
						_this.list = JSON.parse(tags_list);
					}

					if (process.env.NODE_ENV == "development") {
						console.debug('setTagsModal::show.bs.modal', {
							section: _this.section,
							source_id: _this.source_id,
							list: _this.list,
						});
					}
				});
			}
		},
        computed: {
            checkedTags() {
				return Array.isArray(this.list) ? this.list.filter(item => item.isChecked) : [];
			},
        },
    }
</script>